/* Styling specific to the individual job pages */


.smiling-lady-img {
  width: 100vw;
}

.job-page {
  /* Specifying width etc on a div-level to allow for variations */
  width: 75vw;
  display: block;
  margin: auto;
  padding: 5vh 0 0 0;
}

.job-links, .job-links a {
  color: #4F5052B3;
  font-size: 16px;
  transition: ease 0.4s;
}

.job-links a:hover {
  color: #6637f5;
}

.job-header {
  display: flex;
  flex-direction: row;

  .job-summary-div {
    width: 70%;
    .job-title {
      font-size: 30px;
    }
    .job-attributes {
      color: #6637f5;
      padding: 10px 0px;
      font-size: 16px;
      .job-attributes span {
        color: #4F505299;
      }
    }
  }

  .apply-div {
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }

  .apply-button {
    background-color: #6637f5;
    color: #ffffff;
    font-size: 14px;
    border-color: transparent;
    border-style: none;
    border-radius: 30px;
    padding: 0 30px;
    line-height: 1;
    height: 40px;
    font-weight: 400;
    transition: ease 0.4s;
  }

  .apply-button:hover {
    background-color: #4f5052;
    cursor: pointer;
  }
}
.job-description {
  padding-bottom: 20px;
  font-size: 18px;
}

.content-intro {
  margin-bottom: 30px;
}

h3,
h4 {
  font-family: "Medium";
  margin: 20px 0px 10px 0px;
}

h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}

p {
  margin: 10px 0px;
}

ul {
  margin-bottom: 20px;
}

li {
  margin-bottom: 10px;
  padding-left: 10px;
}

/* Adjustments for xs computer screens: */
@media only screen and (max-width: 1024px) {

  .job-page {
    width: 80vw;
  }
  .job-links,
  .job-links a {
    font-size: 14px;
  }
  .job-header {
    .job-summary-div {
      width: 100%;
      .job-title {
        font-size: 26px;
      }
      .job-attributes {
        font-size: 14px;
      }
    }

  }
  .job-description {
    font-size: 16px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;
  }
}

.id-not-found {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
}


/* Adjustments for tablets: */
@media only screen and (max-width: 768px) {

  .smiling-lady-img {
    padding-top: 10vh;
  }

  .job-page {
    width: 85vw;
  }
  .job-links,
  .job-links a {
    font-size: 12px;
  }
  .job-header {
    .job-summary-div {
      .job-title {
        font-size: 24px;
        .job-attributes {
          font-size: 14px;
        }
      }
    }
  }
  .job-description {
    font-size: 14px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }
}

/* Adjustments for mobile devices: */
@media only screen and (max-width: 640px) {
  .job-links,
  .job-links a {
    font-size: 12px;
  }
  .job-header {
    flex-direction: column;
    .job-summary-div {
      .job-title {
        font-size: 22px;
        .job-attributes {
          font-size: 12px;
        }
      }
    }
    .apply-div {
      width: 100%;
      justify-content: center;
    }
    .apply-button {
      margin-bottom: 20px;
    }
  }
  .job-description {
    font-size: 12px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 14px;
  }
  p {
    margin-top: 0;
  }
}
