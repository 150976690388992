/* Styling specific to the footer component */

footer {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 10;
  width: 100vw;
  padding: 5vw 0 20px 0;
  background-color: #000000;
  font-size: 14px;
  line-height: 24px;
}

.footer-div {
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
}


.mx-icon {
  width: clamp(50px, 100px, 120px);
  opacity: 1;
  transition: ease 0.4s;
}

.mx-icon:hover {
  opacity: 0.7;
  transform: translate(0, -8px);
  transition: ease 0.4s;
}

.footer-greenhouse {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.greenhouse {
  margin: 0;
  color: #cccccc;
}

.greenhouse-logo {
  vertical-align: middle;
  flex-flow: column nowrap;
  padding-left: 5.6px;
  width: 95px;
}

.policy-link {
  text-decoration: none;
  color: #cccccc;
  transition: ease 0.4s;
}

.policy-link:hover {
  color: #ffffff;
}

.footer-mx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.gh-privacy, .mx-privacy {
  display: flex;
  align-items: flex-end;
}

.copyright-mx {
  display: flex;
  align-items: flex-end;
  color: #cccccc;
}


/* Adjustments for tablets and below: */
@media only screen and (max-width: 768px) {
  footer {
    font-size: 12px;
  }
}

/* Adjustments for mobile devices: */
@media only screen and (max-width: 640px) {
  
  .footer-div {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .logo-div {
    display: none;
  }

  .gh-privacy {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .footer-greenhouse {
    align-items: flex-start;
  }

  .footer-mx {
    align-items: flex-start;
  }
}