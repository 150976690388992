/* Styling specific to the header component */

header {
  z-index: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100vw;
  background-color: #ffffffb3;
  .logo {
    padding: 35px;
    width: clamp(50px, 6vw, 75px);
    transition: ease 0.4s;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .logo:hover {
    opacity: 0.7;
    transform: translate(0, 5px);
  }
}

@media only screen and (max-width: 768px) {
  header {
    .logo {
      padding: 25px;
    }
  }
}
