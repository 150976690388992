@font-face {
    font-family: "Medium";
    src: local("NBInternationalProMedium"),
      url("../assets/fonts/NB-International-Pro-Medium.woff") format("woff");
  }
  
  @font-face {
    font-family: "Light";
    src: local("NBInternationalProLight"),
      url("../assets/fonts/NB-International-Pro-Light.woff") format("woff");
  }
  
  @font-face {
    font-family: "Regular";
    src: local("NBInternationalProRegular"),
      url("../assets/fonts/NB-International-Pro-Regular.woff") format("woff");
  }